import { Biconomy } from "@biconomy/mexa";
import { ethers } from "ethers";
import React, { useContext, useEffect, useState } from "react";
import Confetti from "react-confetti";
import { CgSpinnerAlt } from "react-icons/cg";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import web3 from "web3";
import { claimNFTGAFunctions } from "../GA/ClaimNFT";
import FetchABI from "../Hooks/GetABI";
import Loader from "../components/Loader";
import SVG from "../components/SVG";
import { APIRoutes } from "../constants/ApiRoutes";
import constantsValues from "../constants/constantsValues";
import { MintContext } from "../context/MintPageContext";
import ApiCall from "../helper/ApiCall";
import checkWhiteListUser from "../helper/CheckWhiteListUser";
import MetaMaskError from "../helper/MetaMaskError";
import UpdateWhiteListUser from "../helper/UpdateWhiteListUser";
import switchNetwork from "../helper/switchNetwork";

export default function Mint() {
  const {
    walletAddress,
    metamaskprovider,
    claimerDetails,
    setWalletAddress,
    ethersProvider,
  } = useContext(MintContext);
  console.log(claimerDetails);
  const { contractAddress, chainName } = useParams();
  const navigate = useNavigate();
  const [showConfetti, setShowConfetti] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [abi, SetABI] = useState("");
  const [count, setCount] = useState(1);
  const [isDisable, setDisable] = useState("");
  const [showClaimModal, setShowClaimModal] = useState(false);

  const [mintDetails, setMintDetails] = useState({
    maxSupply: "",
    totalSupply: "",
    maxMintPerUser: "",
    balanceOf: "",
    cost: "",
  });
  console.log(mintDetails);
  //
  // fetch all data acc. to contract address from db
  async function fetchData() {
    if (
      claimerDetails.contractType === "biconomy" ||
      claimerDetails.contractType === "biconomy1155"
    ) {
      await fetchBiconomyData();
    }

    if (ethersProvider && walletAddress) {
      await fetchMintDetails(claimerDetails.contractType);
    }
    setLoading(false);
  }
  //
  // if contractType is biconomy (of this contractAddress) fetch biconomyKey from db
  async function fetchBiconomyData() {
    const { res, resData } = await ApiCall(
      APIRoutes.getBiconomyContracts,
      "POST",
      {
        contractAddress: contractAddress.toLowerCase(),
        networkType: chainName,
      }
    );
    if (resData.success) {
      if (resData.data[0]?.biconomyKey) {
        setApiKey(resData.data[0]?.biconomyKey);
      } else {
        toast.error("Biconomy Key Not Found");
        setTimeout(() => {
          navigate("/");
        }, [2500]);
        navigate("/404");
      }
    } else if (!resData.success) {
      navigate("/404");
    }
  }
  //
  // fetch mint details of this contract address
  async function fetchMintDetails(typeOfContract) {
    let getABIvar;
    if (!abi) {
      getABIvar = await FetchABI(typeOfContract);
      SetABI(getABIvar);
    } else {
      getABIvar = abi;
    }
    const nftContract = new ethers.Contract(
      contractAddress,
      getABIvar,
      ethersProvider.getSigner()
    );
    console.log(nftContract);
    let maxSupply, totalSupply, maxMintPerUser, balanceOf, cost;

    maxSupply = await nftContract.maxSupply();
    if (
      claimerDetails.contractType === "biconomy" ||
      claimerDetails.contractType === "biconomy1155"
    ) {
      maxMintPerUser = await nftContract.maxMintAllowedPerUser();
      cost = 0;
    } else {
      maxMintPerUser = await nftContract.maxMintPerUser();
      let oldCost = await nftContract.cost();
      cost = web3.utils.fromWei(oldCost.toString(), "ether");
    }

    if (typeOfContract === "ERC1155Collection") {
      totalSupply = await nftContract.totalSupply("1");
      if (walletAddress) {
        balanceOf = await nftContract.balanceOf(walletAddress, "1");
      }
    } else if (claimerDetails.contractType === "biconomy1155") {
      // TODO totalSupply
      console.log(nftContract);
      totalSupply = await nftContract.totalSupply();
      console.log(totalSupply);
      if (walletAddress) {
        balanceOf = await nftContract.balanceOf(walletAddress, "1");
      }
    } else {
      totalSupply = await nftContract.totalSupply();
      if (walletAddress) {
        balanceOf = await nftContract.balanceOf(walletAddress);
      }
    }
    if (
      chainName === "HederaTestnet" &&
      (typeOfContract === "ERC721ACollection" ||
        typeOfContract === "ERC1155Collection")
    ) {
      cost = ethers.utils.formatUnits(await nftContract.cost(), 8);
    }

    setMintDetails({
      maxSupply: parseInt(maxSupply?.toString()),
      totalSupply: parseInt(totalSupply?.toString()),
      maxMintPerUser: parseInt(maxMintPerUser?.toString()),
      balanceOf: parseInt(balanceOf?.toString()),
      cost: cost?.toString(),
    });
  }

  // ------------------biconomy---------------------  //
  async function gaslessTxn() {
    if (walletAddress) {
      if (metamaskprovider && ethersProvider) {
        if (claimerDetails.isWhiteListUser) {
          if (!email) {
            toast.warning("Please share your whitelisted Address");
            return;
          }
          try {
            await checkWhiteListUser(contractAddress, email);
          } catch (err) {
            toast.error(err.message);
            return;
          }
        }
        const signingAccount = new ethers.Wallet(
          process.env.REACT_APP_PRIVATE_KEY
        );
        if (mintDetails.balanceOf >= mintDetails.maxMintPerUser) {
          toast.error("You have exceeded your NFTs available to claim!!");
          return;
        }
        setDisable(true);
        try {
          let signer = ethersProvider.getSigner();
          const nftContract = new ethers.Contract(contractAddress, abi, signer);
          let hash = await nftContract.getHash();
          let messageBytes = ethers.utils.arrayify(hash);
          let signature = await signingAccount.signMessage(messageBytes);
          const biconomy = new Biconomy(metamaskprovider, {
            walletProvider: metamaskprovider,
            apiKey: apiKey, // game developer biconomy api key
            debug: true,
            contractAddresses: [contractAddress], // list of contract address you want to enable gasless on
          });

          biconomy
            .onEvent(biconomy.READY, async () => {
              toast.loading("Your NFT is being minted, please wait...");

              const contractInstance = new ethers.Contract(
                contractAddress,
                abi,
                biconomy.getSignerByAddress(walletAddress)
              );
              let data;
              if (claimerDetails.contractType === "biconomy1155") {
                data = await contractInstance.populateTransaction.mintNFT(
                  signature,
                  []
                );
              } else {
                data = await contractInstance.populateTransaction.mintNFT(
                  count,
                  signature
                );
              }
              console.log(data);
              let txnn = {
                data: data.data,
                to: contractAddress,
                from: walletAddress,
                signatureType: "EIP712_SIGN",
                gasPrice: ethers.utils.parseUnits("200", "gwei"),
                gasLimit: 2000000,
              };
              let transactionHash;

              try {
                let ethersProvider = biconomy.getEthersProvider();

                let txhash = await ethersProvider.send("eth_sendTransaction", [
                  txnn,
                ]);
                // let txhash  =await ethersProvider.sen
                let receipt = await ethersProvider.waitForTransaction(txhash);
                try {
                  await UpdateWhiteListUser(contractAddress, email, count);
                } catch (err) {
                  toast.error(err.message);
                }
                toast.dismiss();
                toast.success("NFT Minted");

                setShowConfetti(true);
                setShowClaimModal(true);
                setDisable(false);

                return txhash;
              } catch (error) {
                if (error.returnedHash && error.expectedHash) {
                  console.log("Transaction hash : ", error.returnedHash);
                  transactionHash = error.returnedHash;
                } else {
                  console.log(error);
                }
                toast.dismiss();
                toast.error(
                  error?.message ? error?.message : "NFT Minting Failed!"
                );
                setDisable(false);
              } finally {
                setDisable(false);
                setCount(1);
                await fetchMintDetails(claimerDetails.contractType);
              }
            })
            .onEvent(biconomy.ERROR, (error, message) => {
              console.log(message);
              console.log(error);
              toast.error(
                error?.message ||
                  "Something went wrong while connecting to Biconomy"
              );
              setDisable(false);
            });
        } catch (error) {
          const errorMessage = MetaMaskError(error.message);
          console.log(error);
          setDisable(false);
          toast.error(errorMessage);
        }
      } else {
        console.log("Ethereum not found!");
        toast.error(
          "Something went wrong! Please check browser console for more details."
        );
      }
    } else {
      toast.error("Connect Metamask First");
    }
  }
  // ------------------ERC721A And ERC1155---------------------  //

  async function mintNFT() {
    if (walletAddress) {
      if (metamaskprovider && ethersProvider) {
        if (claimerDetails.isWhiteListUser) {
          if (!email) {
            toast.warning("Please enter your whitelist address!!");
            return;
          }
          try {
            await checkWhiteListUser(contractAddress, email);
          } catch (err) {
            toast.error(err.message);
            return;
          }
        }
        if (mintDetails.balanceOf >= mintDetails.maxMintPerUser) {
          toast.error("You have exceeded your NFTs available to mint!!");
          return;
        }
        setDisable(true);
        try {
          const signingAccount = new ethers.Wallet(
            process.env.REACT_APP_PRIVATE_KEY
          );
          // let hash = ethers.utils.id(
          //   String(walletAddress) +
          //     String(Math.round(new Date().getTime() / 1000))
          // );
          let signer = ethersProvider.getSigner();
          const nftContract = new ethers.Contract(contractAddress, abi, signer);
          let hash = await nftContract.getHash();
          let messageBytes = ethers.utils.arrayify(hash);
          let signature = await signingAccount.signMessage(messageBytes);
          const costInNumber = Number(mintDetails.cost);
          const formattedCost = costInNumber.toFixed(8);
          let cost = formattedCost * Number(count);
          console.log(cost);
          cost = cost.toFixed(8);
          console.log(cost);
          let nftTx;
          if (claimerDetails.contractType === "ERC721ACollection") {
            nftTx = await nftContract.mintNFT(walletAddress, count, signature, {
              value: ethers.utils.parseEther(cost.toString()),
            });
          } else if (claimerDetails.contractType === "ERC1155Collection") {
            nftTx = await nftContract.mintNFT(
              walletAddress,
              count,
              [],
              signature,
              {
                value: ethers.utils.parseEther(cost.toString()),
              }
            );
          }
          toast.loading("Your NFT is being minted, please wait...");
          await nftTx.wait();
          try {
            await UpdateWhiteListUser(contractAddress, email, count);
          } catch (err) {
            toast.error(err.message);
          }
          toast.dismiss();
          toast.success("NFT Minted");
          setShowConfetti(true);
          setShowClaimModal(true);
          setDisable(false);
          setCount(1);
          await fetchMintDetails(claimerDetails.contractType);
        } catch (error) {
          const errorMessage = MetaMaskError(error.message);
          console.log(error);
          setDisable(false);
          toast.error(errorMessage);
        }
      } else {
        console.log("ethereum not found");
      }
    } else {
      toast.error("Connect Metamask First");
    }
  }

  async function walletConnect() {
    let chainId = await metamaskprovider.request({ method: "eth_chainId" });
    if (chainId !== claimerDetails.chainId) {
      toast.error(
        `You are not connected to  ${
          constantsValues[claimerDetails.chainId].ChainName
        }\n`
      );
      try {
        await switchNetwork(metamaskprovider, claimerDetails.chainId);
      } catch (error) {
        console.log(error);
        toast.error("Please switch your chain first!!");
        return;
      }
      ConnectWallet();
    } else if (chainId == claimerDetails.chainId) {
      ConnectWallet();
    }
  }
  //

  async function ConnectWallet() {
    let accounts;
    let currentAccount = null;

    try {
      accounts = await metamaskprovider.request({
        method: "eth_requestAccounts",
      });
    } catch (error) {
      console.log(error);
      return;
    }

    currentAccount = accounts[0];
    setWalletAddress(currentAccount);
    toast.success("Wallet connected successfully \n");
    return currentAccount;
  }

  //
  //
  useEffect(() => {
    // if (walletAddress) {
    fetchData();
    // }
  }, [ethersProvider, walletAddress]);

  return (
    <>
      {isLoading ? (
        <div className="loader-position">
          <Loader />
        </div>
      ) : (
        <>
          {showConfetti ? (
            <div
              style={{
                position: "absolute",
                left: "0",
                top: "0",
                width: "100%",
                height: "100vh",
              }}>
              <Confetti
                friction={1}
                numberOfPieces={600}
                recycle={false}
                width={window.innerWidth || 300}
                height={window.innerHeight || 200}
              />
            </div>
          ) : null}
          <main className="claimer-page">
            <aside className="content">
              <header className="heading-container">
                <h4 className="content-heading">{claimerDetails.title}</h4>
                <div className="wallet-address-display">
                  <SVG />
                  <div>
                    <p className="address">{`${contractAddress.substring(
                      0,
                      5
                    )}....${contractAddress.substring(
                      contractAddress.length - 5
                    )}`}</p>
                    <a
                      href={`${
                        constantsValues[claimerDetails?.chainId]?.blockExplorer
                      }${contractAddress}`}
                      target={"/blank"}>
                      <p className="text">Contract Details</p>
                    </a>
                  </div>
                </div>
              </header>
              {claimerDetails.description}

              <div className="claimer-description-container">
                <h2 className="claimer-heading">Open Edition</h2>
                <div className="claimer-description">
                  <div className="claimer-description-group">
                    <p className="label">Price</p>
                    <p className="value">
                      {mintDetails?.cost
                        ? Number(mintDetails?.cost) === 0
                          ? "FREE"
                          : mintDetails.cost
                        : null}
                    </p>
                  </div>
                  <div className="claimer-description-group">
                    <p className="label">NFTs Minted</p>
                    <p className="value">
                      {mintDetails.totalSupply}/{mintDetails.maxSupply}
                    </p>
                  </div>
                </div>
              </div>
              {claimerDetails.isWhiteListUser ? (
                <div className="email-input-container">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="email-input"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />

                  <button
                    className="verify-email-btn"
                    onClick={claimNFTGAFunctions.verifyEmailEvent()}>
                    Verify Details
                    <span>Share your whitelisted Address</span>
                  </button>
                </div>
              ) : null}
              {!claimerDetails.contractType ||
              claimerDetails?.contractType === "biconomy1155" ? (
                ""
              ) : (
                <div className="count-container">
                  <div className="count-input">
                    <button
                      disabled={count <= 1}
                      onClick={() => {
                        setCount(count - 1);
                      }}>
                      -
                    </button>
                    <input
                      type="number"
                      id="count"
                      name="count"
                      step="0"
                      min="0"
                      value={count}
                      readOnly="true"
                      // onChange={(e) => countChange(e)}
                    ></input>
                    <button
                      onClick={() => {
                        if (
                          count <=
                          mintDetails.maxMintPerUser -
                            mintDetails?.balanceOf -
                            1
                        ) {
                          setCount(Number(count) + 1);
                        }
                      }}>
                      +
                    </button>
                  </div>
                </div>
              )}

              {claimerDetails?.contractType === "biconomy" ||
              claimerDetails?.contractType === "biconomy1155" ? (
                <button
                  onClick={() => {
                    claimNFTGAFunctions.claimNFTEvent();
                    gaslessTxn();
                  }}
                  className="claim-nft-btn"
                  disabled={isDisable || mintDetails.maxSupply == ""}>
                  {isDisable ? (
                    <span className="state-symbol">
                      <CgSpinnerAlt className="spinner" />
                    </span>
                  ) : (
                    "Claim NFT"
                  )}
                </button>
              ) : (
                <button
                  onClick={() => {
                    claimNFTGAFunctions.claimNFTEvent(); //TODO: new ga
                    mintNFT();
                  }}
                  className="claim-nft-btn"
                  disabled={isDisable || mintDetails.maxSupply == ""}>
                  {isDisable ? (
                    <span className="state-symbol">
                      <CgSpinnerAlt className="spinner" />
                    </span>
                  ) : (
                    "Mint NFT"
                  )}
                </button>
              )}
            </aside>
            <div className="display">
              {!walletAddress ? (
                <button
                  onClick={() => walletConnect()}
                  className="cannect-wallet-btn">
                  Connect Wallet
                </button>
              ) : (
                <button className="cannect-wallet-btn">Connected</button>
              )}
              <span className="display-image-container">
                <img
                  src={`https://${process.env.REACT_APP_PINATA_GATEWAY_URL}/ipfs/${claimerDetails.image}`}
                  alt="claimer-img"
                  className="display-img"
                />
              </span>
            </div>
          </main>
          {showClaimModal ? (
            <div className="modal-body">
              <div className="modal">
                <button
                  className="close"
                  onClick={() => {
                    setShowConfetti(false);
                    setShowClaimModal(false);
                    if (
                      claimerDetails?.contractType === "biconomy" ||
                      claimerDetails?.contractType === "biconomy1155"
                    ) {
                      window.location.reload();
                    }
                  }}>
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <line
                      x1="1.50578"
                      y1="15.7116"
                      x2="15.9245"
                      y2="1.29292"
                      stroke="white"
                      stroke-width="2"
                    />
                    <line
                      x1="1.70711"
                      y1="1.40971"
                      x2="16.1258"
                      y2="15.8284"
                      stroke="white"
                      stroke-width="2"
                    />
                  </svg>
                </button>
                <div className="content-container">
                  <h1 className="heading">
                    You Successfully Minted {claimerDetails.title}
                  </h1>
                  <p className="congratulations-msg">
                    Congratulation! You have successfully minted{" "}
                    {claimerDetails.title}, Check what you have got!!
                  </p>
                </div>
                <div className="content-container">
                  <a
                    class="twitter-share-button"
                    href="https://twitter.com/intent/tweet?text=I've minted my NFT via @lyncworld claimer! Looking forward to unlocking endless possibilities via LYNC!!"
                    target="_blank"
                    rel="noreferrer"
                    data-size="large">
                    <button className="tweet-btn">
                      Let&apos;s tweet about it
                      <svg
                        width="27"
                        height="22"
                        viewBox="0 0 27 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M26.5831 2.75988C25.6089 3.19155 24.5624 3.48322 23.4623 3.61505C24.5974 2.93581 25.4467 1.86676 25.8516 0.607383C24.7851 1.24087 23.6178 1.68678 22.4006 1.92572C21.5821 1.05173 20.4978 0.47243 19.3163 0.277769C18.1348 0.0831079 16.9221 0.283973 15.8664 0.849177C14.8107 1.41438 13.9712 2.3123 13.4781 3.40354C12.9851 4.49477 12.8661 5.71826 13.1396 6.88405C10.9786 6.77555 8.86451 6.21386 6.9346 5.23544C5.0047 4.25702 3.30209 2.88374 1.93727 1.20472C1.47061 2.00972 1.20227 2.94305 1.20227 3.93705C1.20175 4.83188 1.42211 5.713 1.8438 6.50224C2.26549 7.29148 2.87546 7.96443 3.61961 8.46138C2.7566 8.43392 1.91263 8.20073 1.15794 7.78122V7.85122C1.15785 9.10625 1.59198 10.3227 2.38665 11.294C3.18133 12.2654 4.28761 12.932 5.51777 13.1806C4.71719 13.3972 3.87783 13.4291 3.06311 13.2739C3.41019 14.3538 4.08627 15.2981 4.9967 15.9746C5.90714 16.6512 7.00634 17.0261 8.14044 17.0469C6.21525 18.5582 3.83764 19.378 1.39011 19.3744C0.956551 19.3745 0.523362 19.3492 0.0927734 19.2986C2.57716 20.8959 5.46917 21.7437 8.42277 21.7404C18.4211 21.7404 23.8869 13.4594 23.8869 6.27738C23.8869 6.04405 23.8811 5.80838 23.8706 5.57505C24.9338 4.80619 25.8515 3.85409 26.5808 2.76338L26.5831 2.75988V2.75988Z"
                          fill="black"
                        />
                      </svg>
                    </button>
                  </a>

                  {constantsValues[claimerDetails?.chainId]
                    ?.openSeaNFTDetails ? (
                    <a
                      className="view-nft-link"
                      href={
                        claimerDetails.contractType === "ERC1155Collection" ||
                        claimerDetails.contractType === "biconomy1155"
                          ? `${
                              constantsValues[claimerDetails?.chainId]
                                ?.openSeaNFTDetails
                            }${contractAddress}/1`
                          : `${
                              constantsValues[claimerDetails?.chainId]
                                ?.openSeaNFTDetails
                            }${contractAddress}/${mintDetails.totalSupply}`
                      }
                      target="_blank"
                      rel="noopener noreferrer">
                      View your NFT
                    </a>
                  ) : (
                    <a
                      className="view-nft-link"
                      href={`${
                        constantsValues[claimerDetails?.chainId].blockExplorer
                      }${contractAddress}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      View your NFT
                    </a>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
}
