import { PinataSDK } from "pinata";
import { parseError } from "../utils/parseError";

class PinataService {
  constructor() {
    this.pinataClient = new PinataSDK({
      pinataJwt: process.env.REACT_APP_PINATA_JWT_SECRET,
      pinataGateway: process.env.REACT_APP_PINATA_GATEWAY_URL,
    });
  }

  async uploadFile(file) {
    try {
      const response = await this.pinataClient.upload.public.file(file);
      console.log("File uploaded to IPFS:", response);

      return {
        success: true,
        message: "File uploaded successfully.",
        cid: response.cid,
      };
    } catch (error) {
      const errorMessage = parseError(error);
      console.error("Error uploading file to IPFS:", errorMessage);

      return {
        success: false,
        message: `File upload error: ${errorMessage}`,
      };
    }
  }

  async uploadMetadata(
    name,
    description,
    createdBy,
    attributes,
    animation_url,
    image
  ) {
    const metadata = { name, description, createdBy, image };
    if (attributes.length > 0) metadata.attributes = attributes;
    if (animation_url) metadata.animation_url = animation_url;

    const metadataFileName = `metadata_${metadata.name}__${Date.now()}.json`;
    const metadataFile = new File(
      [JSON.stringify(metadata, null, 2)],
      metadataFileName,
      {
        type: "application/json",
      }
    );

    try {
      const response = await this.pinataClient.upload.public.file(metadataFile);
      console.log("Metadata uploaded to IPFS:", response);

      return {
        success: true,
        message: "Metadata uploaded successfully.",
        cid: response.cid,
      };
    } catch (error) {
      const errorMessage = parseError(error);
      console.error("Error uploading Metadata to IPFS:", errorMessage);

      return {
        success: false,
        message: `Metadata upload error: ${errorMessage}`,
      };
    }
  }
}

const pinataService = new PinataService();
export default pinataService;
