import { ethers } from "ethers";
import React, { useContext, useState } from "react";
import Confetti from "react-confetti";
import { useDropzone } from "react-dropzone";
import { CgSpinnerAlt } from "react-icons/cg";
import { IoCheckmarkDoneSharp, IoCloseCircle } from "react-icons/io5";
import { MdContentCopy } from "react-icons/md";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ContractFactory, Web3Provider } from "zksync-web3";
import { deployGAFunctions } from "../../GA/DeployGA";
import { AuthContext } from "../../context/AuthContext";
import constantsValues, {
  defautlChainId,
} from "../../constants/constantsValues";
import CheckFields from "../../helper/CheckFields";
import MetaMaskError from "../../helper/MetaMaskError";
import ApiCall from "../../helper/ApiCall";
import { APIRoutes } from "../../constants/ApiRoutes";
import { homeGAFunctions } from "../../GA/HomeGA";
import { walletConnect } from "../../helper/WalletConnect";
import pinataService from "../../helper/PinataClient";

export default function DeployNewContract() {
  const navigate = useNavigate();
  const loaction = useLocation();
  const category = loaction?.state;
  const {
    address,
    chainToConnect,
    setChainToConnect,
    provider,
    chainNotSupport,
  } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [click, setClick] = useState(false);
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState(null);
  const [name, setName] = useState("");
  const [symb, setSymb] = useState("");
  const [maxMintAllowedPerUser, setMaxMintAllowedPerUser] = useState("");
  const [addWhiteListuser, setAddWhiteListUser] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    symbol: "",
    uri: "",
    maxSupply: "",
    biconomyKey: "",
    maxMintAllowedPerUser: "",
    cost: "",
    description: "",
    title: "",
  });
  const [contractType, setContractType] = useState(
    category === "collection"
      ? "ERC721ACollection"
      : category === "oneOnone"
      ? "ERC721A"
      : "ERC1155"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [deployState, setDeployState] = useState(-1);
  const [deployedContractAddress, setDeployedContractAddress] = useState("");
  const [showMintAmountError, setShowMintAmountError] = useState({
    inputId: "",
    showError: false,
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [".png", ".gif", ".jpeg", ".jpg"] },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        setImage(acceptedFiles[0]);
        setShow(true);
      } else {
        toast.error("Please upload image or gif");
      }
    },
  });

  function handleMintAmount(event) {
    if (formData.maxMintAllowedPerUser === "" || formData.maxSupply === "") {
      setShowMintAmountError({
        inputId: "",
        showError: false,
      });
      return;
    }

    if (
      parseInt(formData.maxSupply) < parseInt(formData.maxMintAllowedPerUser)
    ) {
      setShowMintAmountError({
        inputId: event.target.id,
        showError: true,
      });
    } else {
      setShowMintAmountError({
        inputId: "",
        showError: false,
      });
    }
  }

  if (!category) {
    return <Navigate to="/" />;
  }

  function updateFormData(e) {
    setFormData((prv) => ({ ...prv, [e.target.id]: e.target.value }));
  }

  async function DeployContract() {
    const {
      name,
      symbol,
      uri,
      maxSupply,
      biconomyKey,
      maxMintAllowedPerUser,
      cost,
      title,
      description,
    } = formData;
    let contractAddress;
    try {
      try {
        CheckFields(
          category,
          contractType,
          name,
          symbol,
          uri,
          maxSupply,
          biconomyKey,
          maxMintAllowedPerUser,
          cost,
          title,
          description,
          image
        );
      } catch (err) {
        toast.error(err.message);
        return;
      }

      setIsLoading(true);
      setDeployState(0);
      let imgurl;
      if (category === "collection") {
        const uploadData = await pinataService.uploadFile(image);
        if (!uploadData.success) {
          toast.error(uploadData.message);
          setIsLoading(false);
          setDeployState(null);
          return;
        }

        imgurl = uploadData.cid;
      }
      // Compile Contract
      const { res, resData: response } = await ApiCall(
        APIRoutes.compileContract,
        "POST",
        {
          name,
          symbol,
          walletAddress: address,
          chainId: constantsValues[chainToConnect].chainID,
          networkType: constantsValues[chainToConnect].slug,
          contractType: contractType,
          uri,
          maxSupply,
          biconomyKey,
          forwarder: constantsValues[chainToConnect].forwarder,
          maxMintAllowedPerUser,
          category,
          cost,
          title,
          description,
          image: imgurl,
          isWhiteListUser: addWhiteListuser,
        }
      );
      if (res.status === 200) {
        setDeployState(1);
        if (chainToConnect === "0x118") {
          const signer = new Web3Provider(window.ethereum).getSigner();
          const int = new ethers.utils.Interface(response.abi);
          const contract = new ContractFactory(int, response.bytecode, signer);
          let deploy = await contract.deploy({ type: 0 });
          setDeployState(2);
          await deploy.deployTransaction.wait(4);
          contractAddress = deploy.address.toLocaleLowerCase();
          setDeployedContractAddress(contractAddress);
        } else {
          const factory = new ethers.ContractFactory(
            response.abi,
            response.bytecode,
            provider.getSigner()
          );
          if (chainToConnect === "0x128") {
            const contract = await factory.deploy({ gasLimit: "1000000" });
            await contract.deployed();
            setDeployState(2);
            await contract.deployTransaction.wait(5);
            contractAddress = contract.address.toLocaleLowerCase();
            setDeployedContractAddress(contractAddress);
          } else if (chainToConnect === "0x61") {
            const contract = await factory.deploy({ gasPrice: "20000000000" });
            await contract.deployed();
            setDeployState(2);
            await contract.deployTransaction.wait(5);
            contractAddress = contract.address.toLocaleLowerCase();
            setDeployedContractAddress(contractAddress);
          } else {
            const contract = await factory.deploy();
            await contract.deployed();
            setDeployState(2);
            await contract.deployTransaction.wait(5);
            contractAddress = contract.address.toLocaleLowerCase();
            setDeployedContractAddress(contractAddress);
          }
        }
        // Verify Contract
        const { res, resData } = await ApiCall(
          APIRoutes.verifyContract,
          "POST",
          {
            contract_address: contractAddress,
            verifyId: response.verifyId,
          }
        );
        if (res.ok) {
          setIsLoading(false);
          setDeployState(3);
          setName(formData.name);
          setSymb(formData.symbol);
          setMaxMintAllowedPerUser(formData.maxMintAllowedPerUser);
          setClick(true);
          setFormData({
            name: "",
            symbol: "",
            uri: "",
            maxSupply: "",
            biconomyKey: "",
            maxMintAllowedPerUser: "",
            cost: "",
            description: "",
            title: "",
          });
          if (resData.status) {
            toast.success(contractAddress + " Contract Deploy Successfully");
          } else if (resData.status) {
            toast.error(resData.result.result);
          }
          setIsLoading(false);
          setDeployState(null);
        } else {
          setIsLoading(false);
          setDeployState(null);
          console.log("contract verification failed");
        }
      } else {
        toast.error(response?.msg);
        setIsLoading(false);
        setDeployState(null);
      }
    } catch (err) {
      console.log(err);
      const errorMessage = MetaMaskError(err.message);
      toast.error(errorMessage);
      setIsLoading(false);
      setDeployState(null);
      return;
    }
  }
  let d1 = new Date();
  let str = d1.toISOString();
  if (click) {
    setTimeout(() => {
      navigate(`/contract/${deployedContractAddress}`, {
        state: {
          contractAddress: deployedContractAddress,
          contractName: name,
          category: category,
          networkType: constantsValues[chainToConnect]?.slug,
          contractType: contractType,
          symbol: symb,
          chainId: chainToConnect,
          contractCreatedAt: str,
          isWhiteListUser: addWhiteListuser,
          maxMintAllowedPerUser: Number(maxMintAllowedPerUser),
        },
      });
    }, 5000);
  }

  return (
    <>
      {click ? (
        <div
          style={{
            position: "absolute",
            left: "0",
            top: "0",
            width: "100%",
            height: "100vh",
          }}>
          <Confetti
            friction={1}
            numberOfPieces={600}
            recycle={false}
            width={window.innerWidth || 300}
            height={window.innerHeight || 200}
          />
        </div>
      ) : null}

      <div className="new-contract mintMain" style={{ paddingInline: "0" }}>
        <main className="main-content">
          <article
            style={{ paddingTop: isLoading ? "0" : "2rem" }}
            className="content mintTop">
            <h1 className="content-heading">
              Write your contract details carefully
            </h1>
            <p className="content-description">
              Your contract name is a key identifier that appears wherever your
              contract is mentioned or listed. Typically, it reflects your
              artist name, collection name, brand, or project name.
            </p>
            <p className="content-description">
              Your contract name is a key identifier that appears wherever your
              contract is mentioned or listed. Typically, it reflects your
              artist name, collection name, brand, or project name.
            </p>
            <div>
              {deployedContractAddress !== "" ? (
                <a
                  href={
                    constantsValues[chainToConnect].blockExplorer +
                    deployedContractAddress
                  }
                  target="_blank"
                  rel="noopener noreferrer">
                  <button className="deploy-btn" disabled={isLoading}>
                    View on block explorer
                  </button>
                </a>
              ) : chainNotSupport != true && chainToConnect ? (
                <button
                  className="deploy-btn"
                  form="deploy-form"
                  disabled={isLoading || !address}>
                  {`Deploy On ${constantsValues[chainToConnect]?.ChainName}`}
                </button>
              ) : (
                <button
                  onClick={() => {
                    homeGAFunctions.connectWalletEvent();
                    walletConnect(defautlChainId);
                  }}
                  className="deploy-btn">
                  {chainNotSupport && address
                    ? "Switch Network"
                    : "Connect Wallet"}
                </button>
              )}
            </div>

            {isLoading ? (
              <div className="deployment-processing-states">
                <div className="states-description">
                  <>
                    <p className="state-text">Compiling your contract</p>
                    {deployState >= 0 ? (
                      <span className="state-symbol">
                        {deployState === 0 ? (
                          <CgSpinnerAlt className="spinner" />
                        ) : (
                          <IoCheckmarkDoneSharp className="check-mark" />
                        )}
                      </span>
                    ) : null}
                  </>
                </div>
                <div className="states-description">
                  <>
                    <p className="state-text">Deploying your contract</p>
                    {deployState >= 1 ? (
                      <span className="state-symbol">
                        {deployState === 1 ? (
                          <CgSpinnerAlt className="spinner" />
                        ) : (
                          <IoCheckmarkDoneSharp className="check-mark" />
                        )}
                      </span>
                    ) : null}
                  </>
                </div>
                <div className="states-description">
                  <>
                    <p className="state-text">Verifying your contract</p>
                    {deployState >= 2 ? (
                      <span className="state-symbol">
                        {deployState === 2 ? (
                          <CgSpinnerAlt className="spinner" />
                        ) : (
                          <IoCheckmarkDoneSharp className="check-mark" />
                        )}
                      </span>
                    ) : null}
                  </>
                </div>
              </div>
            ) : deployedContractAddress !== "" ? (
              <div
                className="deployed-address-container"
                onClick={() => {
                  navigator.clipboard.writeText(deployedContractAddress);
                  toast.success("Contract Address Copied to Clipboard.");
                }}>
                Your Deployed Contract Address is:
                <div className="contract-address-display">
                  <p className="contract-address">{deployedContractAddress}</p>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(deployedContractAddress);
                      toast.success("Contract Address Copied to Clipboard.");
                    }}
                    className="copyBtn contractAddressCoptBtn">
                    <MdContentCopy />
                  </button>
                </div>
              </div>
            ) : null}
          </article>

          <form
            className={
              category == "collection"
                ? "contract-information mBottom"
                : "contract-information"
            }
            id="deploy-form"
            onSubmit={(e) => {
              e.preventDefault();
              deployGAFunctions.deployOnPolygonEvent();
              DeployContract();
            }}>
            {category === "oneOnone" || category === "multiple" ? null : (
              <>
                {" "}
                <div className="information-group">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    {!show ? (
                      <div className="image-cont">
                        <p className="add">+</p>
                        <p className="add_upload">Upload Image or GIF</p>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {files.map((path) => {
                      return (
                        <div className="information-group-img">
                          <img key={path} src={path.preview} />
                          <IoCloseCircle
                            className="remove-img-btn"
                            onClick={() => {
                              setFiles([]);
                              setImage(null);
                              setShow(false);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="information-group">
                  <label className="label">Title</label>
                  <input
                    className="name"
                    type="text"
                    placeholder="Title"
                    value={formData.title}
                    name="title"
                    id="title"
                    required={true}
                    onChange={(e) => updateFormData(e)}
                  />
                </div>
                <div className="information-group desc">
                  <label className="label">Description</label>
                  <textarea
                    className="name"
                    placeholder="Description"
                    type="text"
                    value={formData.description}
                    name="description"
                    id="description"
                    required={true}
                    onChange={(e) => updateFormData(e)}
                  />
                </div>
              </>
            )}
            <div className="information-group">
              <label className="label">Contract Name</label>
              <input
                className="name"
                type="text"
                placeholder="Contract Name"
                value={formData.name}
                name="name"
                id="name"
                required={true}
                onChange={(e) => updateFormData(e)}
              />
            </div>

            <div className="information-group">
              <label className="label">Contract Symbol</label>
              <input
                className="name"
                type="text"
                value={formData.symbol}
                placeholder="Symbol"
                name="symbol"
                id="symbol"
                required={true}
                onChange={(e) => updateFormData(e)}
              />
            </div>
            <div className="information-group">
              <label className="label">Choose Chain</label>
              <select
                className="name"
                id="chain"
                name="chain"
                onChange={(e) => {
                  setChainToConnect(e.target.value);
                }}
                value={chainToConnect}>
                {/* map on const obj. */}
                {chainNotSupport === true ? (
                  <option>-- Select Chain --</option>
                ) : null}
                {Object.keys(constantsValues).map((keyName, i) => (
                  <option value={keyName} key={i}>
                    {constantsValues[keyName].ChainName}
                  </option>
                ))}
              </select>
            </div>
            <div className="information-group">
              <label className="label">Type</label>
              <select
                className="name"
                id="mySelect"
                name="contractType"
                value={contractType}
                onChange={(e) => setContractType(e.target.value)}>
                {category === "collection" || category === "oneOnone" ? (
                  <option
                    value={
                      category === "collection"
                        ? "ERC721ACollection"
                        : "ERC721A"
                    }>
                    ERC721A
                  </option>
                ) : null}

                {category === "collection" || category === "multiple" ? (
                  <option
                    value={
                      category === "collection"
                        ? "ERC1155Collection"
                        : "ERC1155"
                    }>
                    ERC1155
                  </option>
                ) : null}

                {category === "collection" &&
                constantsValues[chainToConnect]?.forwarder ? (
                  <>
                    <option value="biconomy">
                      ERC721A with Biconomy (Gasless)
                    </option>
                    <option value="biconomy1155">
                      ERC1155 with Biconomy (Gasless)
                    </option>
                  </>
                ) : null}
              </select>
              {category === "collection" ? (
                <div className="biconomy-type-container">
                  <div className="information-group token">
                    <label className="label">Token URI</label>
                    <input
                      className="name"
                      type="text"
                      value={formData.uri}
                      placeholder="Token URI"
                      id="uri"
                      required={true}
                      name="uri"
                      onChange={(e) => updateFormData(e)}
                    />
                  </div>
                  <div className="information-group">
                    <label className="label">Max. Mint Allowed Per. User</label>
                    <input
                      className="name"
                      type="number"
                      value={formData.maxMintAllowedPerUser}
                      placeholder="Max. Mint Allowed Per. User"
                      id="maxMintAllowedPerUser"
                      name="maxMintAllowedPerUser"
                      required={true}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => updateFormData(e)}
                      onBlur={(event) => handleMintAmount(event)}
                    />
                    {showMintAmountError.inputId === "maxMintAllowedPerUser" &&
                    showMintAmountError.showError === true ? (
                      <small>
                        Max. Mint Allowed Per User should be less than Max
                        Supply.
                      </small>
                    ) : null}
                  </div>
                  <div className="information-group">
                    <label className="label">Max Supply</label>
                    <input
                      className="name"
                      type="number"
                      value={formData.maxSupply}
                      placeholder="Max. Supply"
                      id="maxSupply"
                      name="maxSupply"
                      required={true}
                      onChange={(e) => updateFormData(e)}
                      onWheel={(e) => e.target.blur()}
                      onBlur={(event) => handleMintAmount(event)}
                    />
                    {showMintAmountError.inputId === "maxSupply" &&
                    showMintAmountError.showError === true ? (
                      <small>
                        Max. Supply should be greater than Max Mint Allowed Per
                        User.
                      </small>
                    ) : null}
                  </div>
                  {contractType != "biconomy" &&
                  contractType != "biconomy1155" &&
                  category === "collection" ? (
                    <div className="information-group">
                      <label className="label">Cost</label>
                      <input
                        className="name"
                        type="text"
                        maxLength={8}
                        value={formData.cost}
                        placeholder="Cost"
                        id="cost"
                        name="cost"
                        required={true}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          if (/^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                            updateFormData(e);
                          }
                        }}
                      />
                    </div>
                  ) : null}

                  {contractType === "biconomy" ||
                  contractType == "biconomy1155" ? (
                    <div className="information-group">
                      <label className="label">Biconomy Key</label>
                      <input
                        className="name"
                        type="text"
                        value={formData.biconomyKey}
                        placeholder="Biconomy Key"
                        id="biconomyKey"
                        name="biconomyKey"
                        required={true}
                        onChange={(e) => updateFormData(e)}
                      />
                    </div>
                  ) : null}
                  {category === "collection" ? (
                    <div className="information-group whitelist-box">
                      <label className="label add-whitelist">
                        <input
                          className="name"
                          type="checkbox"
                          value={addWhiteListuser}
                          id="addWhiteListuser"
                          name="addWhiteListuser"
                          onChange={(e) => {
                            setAddWhiteListUser(e.target.checked);
                          }}
                        />
                        Add WhiteList User
                      </label>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </form>
        </main>
      </div>
    </>
  );
}
